$left-block-width: 46.875%;
$right-block-width: 53.125%;
$product-image-wrapper-width: 50px;

$black: #0A0A0A;
$pink: #DB1C78;

.widget320x90 {
    display: flex;
    width: 100%;
    height: 100%;
    font-family: 'montserrat-regular', Helvetica, sans-serif;

    &__left {
        width: 94px;
        height: 100%;
        margin: auto 7px;
        padding-right: 7px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        &.no-logo {
            @include flex(column, center, center);
            box-sizing: border-box;
            background-color: $white;
            border-right: 1px solid $gray-gallery;
            text-align: center;
        }
    }

    &__logo {
        width: 100%;
        height: 45px;
        object-fit: contain;
        cursor: pointer;
    }

    &__offer {
        display: inline-block;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        line-height: 16px;

        max-height: 36px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &__link {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        line-height: 1;
        word-break: break-all;
    }

    &-product {
        @include flex(row, center, stretch);
        width: 205px;
        cursor: pointer;
    }

    &-product__image-wrapper {
        @include flex(column, center, center);
        width: 90px;
        height: 90px;
    }

    &-product__image {
        display: block;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        object-fit: cover;
    }

    &-product__image--zoomed {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }

    &-product__content-wrapper {
        @include flex(column, center, center);
        width: 100px;
        text-align: center;
    }

    &-product__title {
        font-weight: bold;
        color: $black;
        @include multiline-text-ellipsis(11px, 1.2, 2);
        text-align: left;
        word-wrap: break-word;
    }

    &-product__brandName {
        @include multiline-text-ellipsis(9px, 1.2, 1);
        color: $black;
        font-family: 'montserrat-regular', Helvetica, sans-serif;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 5px 0;
        text-align: left;
    }

    &-product__offer-message {
        font-size: 12px;
        line-height: 1.33;
    }

    &-product__price-box {
        @include flex(row, space-between, center);
        width: 100%;
        box-sizing: border-box;
    }

    &-product__price {
        font-size: 10px;
        line-height: 1.14;
        color: $black;
        font-weight: 600;
    }

    &-product__discount {
        font-size: 10px;
        line-height: 1.14;
        color: $pink;
        font-weight: 600;
    }

    &-product__price--single {
        text-align: center;
    }

    &-product__price--original {
        margin-left: 6px;
        font-size: 10px;
        font-style: italic;
        color: $gray-dusty;
        text-decoration: line-through;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-product__action {
        max-width: 73px;
        max-height: 22px;
        padding: 5px 15px;
        margin-top: 2px;
        font-size: 9px;
        color: $white;
        text-transform: uppercase;
        border: none;
        border-radius: 1.5px;
        cursor: pointer;
        @include disable-focus-outline;
    }

    &-product__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        padding: 10px 0;
        margin: 0 0 0 8px;
        width: calc(100% - 8px) !important;
        height: 100%;
        @include disable-focus-outline;
    }

    &__action {
        padding: 9px 18px;
        font-size: 11px;
        line-height: 1;
        display: none;
        color: $white;
        text-transform: uppercase;
        background-color: $red-violet;
        border: none;
        border-radius: 2px;
        cursor: pointer;

        @include disable-focus-outline;
    }

    &_kcp {
        .widget320x90__logo {
            width: 74px;
            height: 29px;
        }
    }

    &__branding-wrapper {
        width: 14px;
        height: 14px;
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: pointer;

        .widget320x90__branding-main {
            width: 14px;
            height: 14px;
        }

        .widget320x90__branding-hover {
            display: none;
        }

        &:hover {
            background: none;
            width: 52px;
            height: 9px;

            .widget320x90__branding-main {
                display: none;
            }

            .widget320x90__branding-hover {
                display: block;
                width: 52px;
                height: 9px;
                cursor: pointer;
            }

        }
    }
}

;@import "sass-embedded-legacy-load-done:15";