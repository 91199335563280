$container-padding: 8px;

.pulsing-message {
    @include flex(column, center, center);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: $container-padding;
    box-sizing: border-box;
    font-family: "proxima_nova_semibold", Helvetica, sans-serif;
    line-height: 1;
    z-index: 11;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.55);
    color: $white;
    animation-name: container-animation;
    animation-duration: 5.5s;
    animation-iteration-count: infinite;
}

.pulsing-message--row {
    flex-direction: row;

    .pulsing-message__text {
        margin-left: 20px;
        margin-top: 0;
    }

    .pulsing-message__title {
        font-size: 20px;
    }

    .pulsing-message__subtitle {
        margin-top: 4px;
    }
}

.pulsing-message__close {
    @include close-icon(16px, 3px);
    position: absolute;
    top: $container-padding;
    right: $container-padding;
}

.pulsing-message__icon {
    width: auto;
    max-width: 68px;
    max-height: 100%;
}

.pulsing-message__text {
    margin-top: 10px;
    text-align: center;
}

.pulsing-message__title {
    font-size: 24px;
}

.pulsing-message__subtitle {
    display: inline-block;
    margin-top: 10px;
    font-size: 14px;
}

@keyframes container-animation {
    0%, 11% {
        opacity: 0;
    }
    11% {
        opacity: 1;
        pointer-events: auto;
    }
    11%, 33% {
        opacity: 1;
        pointer-events: auto;
    }
    44% {
        opacity: 0;
    }
    100% {opacity: 0;}
}

;@import "sass-embedded-legacy-load-done:7";