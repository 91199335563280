$white: #FFFFFF;
$gray-wild-sand: #F5F5F5;
$gray-gallery: #EEEEEE;
$gray-alto: #DFDFDF;
$gray-silver: #C6C6C6;
$gray-silver-2: #C7C7C7;
$gray-nobel: #B3B3B3;
$gray-dusty: #9B9B9B;
$gray-dusty-2: #999999;
$gray-abbey: #58595B;
$gray-tundora: #4A4A4A;
$black: #000000;

$blue-dodger: #38A4F5;
$blue-oxford: #313D4F;

$red-violet: #DA1781;
$red-pomegranate: #F64316;

$yellow-sea: #ffaf06;

;@import "sass-embedded-legacy-load-done:5";