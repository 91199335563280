@import 'reset';
@import 'font_family';
@import 'icomoon';

@import "variables";
@import "mixins";
@import "colors";
@import 'ui/spinner';
@import 'ui/pulsing-message';
@import 'ui/rotate-discount';
@import 'ui/scale-discount';
@import 'views/one';
@import 'views/carousel';
@import 'views/multiproduct';

@import './purchase_modal_overrides';

@import './units/Fullx90';
@import './units/320x90';
@import './units/Fullx50';
@import './units/970x250';
@import './units/300x600';
@import './units/300x250';
@import './units/320x100';
@import './units/336x280';
@import './units/300x300';
@import './units/160x600';
@import './units/160xFull';

body {
    font-family: 'proxima_nova_regular', Helvetica, sans-serif;
}

.wrapper {
    position: relative;
    background-color: $white;
    border: 1px solid $gray-gallery;
    box-sizing: border-box;
    overflow: hidden;
}

.main-product {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px 10px 60px 10px;
    box-sizing: border-box;
}

.flex-area {
    display: flex;
}

.hide {
    display: none;
}

.scrollarea {
    width: 100%;
    padding-right: 8px;
    .scrollbar-container {
        &.vertical {
            width: 6px;
            border-radius: 8px;
            .scrollbar {
                width: 6px;
                margin-left: auto;
                margin-right: auto;
                border-radius: 8px;
                background: rgba(0, 0, 0, 0.4);
            }
        }
        &.active, &:hover {
            background: rgba(128, 128, 128, 0.2);
        }
    }
}

.brand-link {
    position: absolute;
    display: flex;
    align-items: center;
    right: 4px;
    bottom: 0;

    .brand-link__text {
        font-size: 10px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.2px;
        text-align: center;
        color: $blue-oxford;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.products-view {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 12px 0;
    position: relative;

    .products-slide {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;

        & > div > div {
            width: auto;
        }
    }
}
