.discount-rotate {
    animation: .5s rotate ease-in-out infinite;
}

@keyframes rotate {
    0%, 100% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(10deg);
    }
}

;@import "sass-embedded-legacy-load-done:8";