.discount-scale {
    animation: 2s scale infinite;
}

.discount-scale-pseudo-elem {
    &:before {
        animation: 1.2s scale-pseudo infinite;
    }
}

@keyframes scale {
    0%, 100% {
        transform: scale(1);
        animation-timing-function: cubic-bezier(.22,0,.43,0.8);
    }
    50% {
        transform: scale(1.31);
        animation-timing-function: cubic-bezier(.22,0,.43,1.4);
    }
}

@keyframes scale-pseudo {
    0%, 100% {
        transform: scale(1);
        animation-timing-function: cubic-bezier(.22,0,.43,1.4);
    }
    50% {
        transform: scale(1.15);
        animation-timing-function: cubic-bezier(.22,0,.43,0.8);
    }
}

;@import "sass-embedded-legacy-load-done:9";