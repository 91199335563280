$branded-logo-height: 100px;
$branded-offer-height: 50px;
$branded-data-height: $branded-logo-height + $branded-offer-height;
$product-image-wrapper-size: 122px;

.widget300x600 {
    @include flex(column, stretch, stretch);
    height: 100%;

    &__left {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        height: $branded-data-height;

        &.no-logo {
            @include flex(column, space-between, stretch);
            box-sizing: border-box;
            background-color: $white;
            border-right: 1px solid $gray-gallery;
            text-align: center;
        }

        &.no-logo .widget300x600__link {
            margin-top: 40px;
        }
    }

    &__logo {
        height: $branded-logo-height;
        cursor: pointer;
        padding-top: 16px;
    }

    &__offer-container {
        @include flex(row, center, center);
        height: $branded-offer-height;
        font-size: 18px;
    }

    &__offer {
        font-weight: 600;
        font-size: 18px;
        text-align: center;
    }

    &-slide {
        @include flex(column, center, center);
        @include disable-focus-outline;
        position: relative;
        padding: 7px 4px;
        box-sizing: border-box;

        &.active {

            .widget300x600-slide__image {
                opacity: .25;
            }

            .widget300x600-slide__data {
                @include flex(column, center, center);
            }
        }

        &__image-wrapper {
            @include flex(column, center, center);
            width: $product-image-wrapper-size;
            height: $product-image-wrapper-size;
        }

        &__image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &__image--zoomed {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }

        &__data {
            width: $product-image-wrapper-size;
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &__title {
            @include multiline-text-ellipsis(16px, 1, 2);
            font-weight: bold;
            color: $gray-tundora;
        }

        &__price-box {
            width: 100%;
            @include flex(column-reverse, center, center);
            margin: 4px 0;
        }

        &__price {
            font-size: 14px;
            line-height: 1.25;
            color: $gray-tundora;
        }

        &__price--original {
            margin-bottom: 3px;
            font-size: 12px;
            line-height: 1.1;
            font-style: italic;
            color: $gray-dusty;
            text-decoration: line-through;
        }

        &__price, &__price--original {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
        }

        &__action {
            width: 116px;
            height: 43px;
            font-size: 16px;
            font-weight: 600;
            color: $white;
            text-transform: uppercase;
            border: none;
            border-radius: 2px;
            cursor: pointer;

            @include disable-focus-outline;
        }
    }

    &_kcp {
        .widget300x600__left {
            justify-content: space-around;
            align-items: center;
        }

        .widget300x600__logo {
            width: 150px;
            height: 59px;
        }
    }

    &__branding-wrapper {
        width: 100%;
        height: 14px;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: flex-end;

        .widget300x600__branding-main {
            width: 83px;
            height: 14px;
            cursor: pointer;
        }

    }

    @include slick-controls(13px);

    /* -------- slick-carousel styles overrides -------- */

    .carousel-wrapper {
        padding-top: 0;
        height: calc(100% - (#{$branded-data-height}) - 14px);
    }

    .view-carousel {
        padding: 7px 10px 5px;
        box-sizing: border-box;
    }

    .view-carousel {
        height: 100%;
        margin: 0;
    }

    .slick-list {
        width: 100%;
    }

    .slick-track {
        height: 100%;
    }

    .slick-slide {
        @include flex(column, flex-start, stretch);
    }

    .slick-slide div {
        @include disable-focus-outline;
    }

    .slick-slide > div {
        display: flex;
    }
}

;@import "sass-embedded-legacy-load-done:18";