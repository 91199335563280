@font-face {
    font-family: "proxima_nova_light";
    src: url("./fonts/proxima-nova-light-webfont.woff2") format("woff2"),
         url("./fonts/proxima-nova-light-webfont.woff") format("woff"),
         url("./fonts/proxima-nova-light-webfont.svg#proxima_nova_light") format("svg");
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: "proxima_nova_bold";
    src: url("./fonts/proxima-nova-bold-webfont.woff2") format("woff2"),
         url("./fonts/proxima-nova-bold-webfont.woff") format("woff"),
         url("./fonts/proxima-nova-bold-webfont.svg#proxima_nova_bold") format("svg");
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: "proxima_nova_regular";
    src: url("./fonts/proxima-nova-regular-webfont.woff2") format("woff2"),
         url("./fonts/proxima-nova-regular-webfont.woff") format("woff"),
         url("./fonts/proxima-nova-regular-webfont.svg#proxima_nova_regular") format("svg");
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: "proxima_nova_semibold";
    src: url("./fonts/proxima-nova-semibold-webfont.woff2") format("woff2"),
         url("./fonts/proxima-nova-semibold-webfont.woff") format("woff"),
         url("./fonts/proxima-nova-semibold-webfont.svg#proxima_nova_semibold") format("svg");
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: "montserrat-regular";
    src: url("./fonts/Montserrat/Montserrat-Regular.ttf") format("ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "montserrat-bold";
    src: url("./fonts/Montserrat/Montserrat-Bold.ttf") format("ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "montserrat-italic";
    src: url("./fonts/Montserrat/Montserrat-Italic.ttf") format("ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "montserrat-semibold";
    src: url("./fonts/Montserrat/Montserrat-SemiBold.ttf") format("ttf");
    font-weight: normal;
    font-style: normal;
}

;@import "sass-embedded-legacy-load-done:1";