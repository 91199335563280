@mixin multiline-text-ellipsis($font-size, $line-height, $line-count) {
    display: block; /* Fallback for non-webkit */
    display: -webkit-box;
    -webkit-line-clamp: $line-count;
    max-height: $font-size*$line-height*$line-count; /* Fallback for non-webkit */
    font-size: $font-size;
    line-height: $line-height;
    overflow: hidden;
    text-overflow: ellipsis;

    & {
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
    }
    /* autoprefixer: on */
}

@mixin disable-focus-outline {
    &:focus {
        outline: none;
    }
}

@mixin flex($direction: row, $justify-content: flex-start, $align-items: flex-start, $wrap: nowrap, $align-content: stretch){
    display: flex;
    flex-flow: $direction $wrap;
    justify-content: $justify-content;
    align-items: $align-items;

    @if $wrap == wrap or $wrap == wrap-reverse{
        align-content: $align-content;
    }
}

@mixin slick-controls($control-wrapper-width, $control-size: small) {
    & {
        .slick-prev, .slick-next {
            width: $control-wrapper-width;
            height: 100%;
            background-color: $white;

            &:before {
                position: absolute;
                top: 50%;
            }
        }

        .slick-prev {
            left: 0;

            &:before {
                left: 50%;
                @if $control-size == small {
                    width: 8px;
                    height: 8px;
                    border-bottom: 2px solid $gray-silver;
                    border-left: 2px solid $gray-silver;
                    transform: translateY(-50%) translateX(-25%) rotate(45deg) skew(2deg, 2deg);
                }

                @if $control-size == large {
                    width: 14px;
                    height: 14px;
                    border-bottom: 4px solid $gray-silver;
                    border-left: 4px solid $gray-silver;
                    border-radius: 3px;
                    transform: translateY(-50%) translateX(-50%) rotate(45deg) skew(4deg, 4deg);
                }
            }
        }

        .slick-next {
            right: 0;

            &:before {
                right: 50%;
                @if $control-size == small {
                    width: 8px;
                    height: 8px;
                    border-bottom: 2px solid $gray-silver;
                    border-right: 2px solid $gray-silver;
                    transform: translateY(-50%) translateX(25%) rotate(-45deg) skew(-2deg, -2deg);
                }

                @if $control-size == large {
                    width: 14px;
                    height: 14px;
                    border-bottom: 4px solid $gray-silver;
                    border-right: 4px solid $gray-silver;
                    border-radius: 3px;
                    transform: translateY(-50%) translateX(50%) rotate(-45deg) skew(-4deg, -4deg);
                }
            }
        }
    }
}

@mixin close-icon($button-size: 10px, $cross-part-thickness: 2px, $color: $white) {
    position: relative;
    width: $button-size;
    height: $button-size;
    cursor: pointer;
    border: none;
    background-color: transparent;

    &:before, &:after {
        position: absolute;
        top: 50%;
        content: '';
        width: 100%;
        height: $cross-part-thickness;
        background-color: $color;
        border-radius: 5px;
    }

    &:before {
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }

    &:after {
        right: 50%;
        transform: translateX(50%) translateY(-50%) rotate(45deg);
    }
}

;@import "sass-embedded-legacy-load-done:4";