
.view-one {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .view-carousel {

        .icon {
            top: calc(50% - 20px);
        }
    }

    .price-box {
        position: absolute;
        top: 2px;
        right: 10px;

        .price-new {
            font-size: 17px;
            text-align: right;
            color: $gray-dusty;
            font-family: proxima_nova_semibold, Helvetica, sans-serif;
        }

        .price-old {
            font-family: proxima_nova_regular, Helvetica, sans-serif;
            font-size: 12px;
            text-align: right;
            color: $red-pomegranate;
            text-decoration: line-through;
        }
    }

    .details {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 6px;
    }

    .product-name {
        padding-right: 30px;
        font-family: proxima_nova_semibold, Helvetica, sans-serif;
        font-size: 17px;
        line-height: 21px;
        text-align: left;
        color: $black;
        overflow: hidden;
        white-space: nowrap;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }

    .text-ellipsis {
        width: 215px;
        overflow: hidden;
        white-space: nowrap;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }

    .all-desc {
        position: relative;
        padding-right: 10px;
        font-size: 9px;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: left;
        color: $gray-tundora;
        line-height: 18px;
        font-family: Roboto, Helvetica, sans-serif;
        text-transform: uppercase;
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: 2px;
            transform: translateY(-50%);
            border: 3px solid transparent;
            border-top: 6px solid $black;
        }
    }

    .description {
        padding: 10px 0;
        width: 100%;
        flex-shrink: 1;
        overflow-x: hidden;
        overflow-y: auto;



        iframe, img {
            width: 100% !important;
            height: auto;
        }

        &__title {
            margin-bottom: 10px;
            font-size: 14px;
            text-align: left;
            color: $gray-tundora;
            font-family: proxima_nova_semibold, Helvetica, sans-serif;
        }

        &__text {
            font-size: 12px;
            line-height: 21px;
            text-align: left;
            color: $gray-tundora;
            font-family: proxima_nova_regular, Helvetica, sans-serif;

            &.terms {
                margin-top: 8px;
                font-size: 10px;
            }
        }
    }

    .scrollbar {
        overflow-y: auto;
        margin-top: 10px;

        &::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            border-radius: 10px;
            background-color: $gray-wild-sand;
        }

        &::-webkit-scrollbar
        {
            width: 6px;
            background-color: $gray-wild-sand;
        }

        &::-webkit-scrollbar-thumb
        {
            border-radius: 15px;
            -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.1);
            background-color: $gray-nobel;
        }
    }

    .disabled {
        pointer-events: none;
        background-color: gray;
    }

    .btn {
        position: relative;
        border: none;
        outline: none;
        text-align: center;
        cursor: pointer;
        background: transparent;

        &-orange {
            width: 100%;
            height: 42px;
            min-height: 42px;
            border-radius: 1px;
            background: $yellow-sea;
            font-family: proxima_nova_bold, Helvetica, sans-serif;
            font-size: 17px;
            color: $white;
        }

        &-fixed-bottom {
            position: absolute;
            top: 12px;
            max-width: 278px;
            width: 100%;
            z-index: 4;
        }
    }

    &.widget250 {
        height: auto;

        .carousel-wrapper {
            padding-top: 14px;
        }

        .btn-fixed-bottom {
            position: relative;
            top: 0;
            bottom: auto;
        }
    }

    &.widget600 {
        padding-bottom: 60px;

        .price-box {
            top: 10px;
            right: 10px;
        }

        .scrollarea {
            padding-right: 0;
            z-index: 1;
        }

        .wrapper-btn-fixed-bottom {
            background: $white;
            width: 100%;
            height: 66px;
            position: absolute;
            bottom: 0;
            z-index: 2;
        }
    }
}

;@import "sass-embedded-legacy-load-done:10";