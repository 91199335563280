
.view-multiproduct {
    font-family: Helvetica, sans-serif;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .product {
        position: relative;
        width: 130px;
        border: solid 1px $gray-alto;
        cursor: pointer;
        height: 105px;
        margin-bottom: 10px;
        box-sizing: border-box;

        .product__title {
            display: block;
            text-align: center;
            font-size: 10px;
            font-weight: bold;
            color: $gray-tundora;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 6px;
            line-height: 12px;
            padding: 0 10px;
        }

        .product__brand {
            margin-top: 4px;
            margin-left: 10px;
            color: green;
            font-family: proxima_nova_regular, Helvetica, sans-serif;
            font-weight: 100;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 10px;
            max-width: 100px;
            font-size: 10px;
        }

        .product__image {
            display: block;
            margin: 5px 10px 0 10px;
            width: 110px;
            height: 62px;
            text-align: center;
            img {
                max-height: 100%;
                max-width: 100%;
                height: auto;
            }
        }

        .product__button {
            width: 122px;
            height: 24px;
            border-radius: 1px;
            background-color: $red-pomegranate;
            border: 0;
            color: $white;
            cursor: pointer;
            outline: none;
            display: none;
            margin: 2px auto;
        }

        .product-box {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:hover, &.active {
            border-color: $red-pomegranate;

            .product__button {
                display: block;
                position: absolute;
                bottom: 2px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &.widget600 {
        .product {
            position: relative;
            height: 186px;
            margin-bottom: 7px;

            .product__title {
                height: 32px;
                line-height: 16px;
                white-space: normal;
            }

            .product__image {
                height: 100px;
            }
            .product__price {
                margin-left: 10px;
                font-size: 10px;
                font-family: proxima_nova_semibold, Helvetica, sans-serif;
            }
            .product__brand {
                margin-top: 2px;
                margin-left: 10px;
                font-size: 12px;
                font-family: proxima_nova_regular, Helvetica, sans-serif;
                line-height: 20px;
            }
            .product__button {
                margin-top: 10px;
            }
            .product__title {
                height: 24px;
                line-height: 24px;
                margin-top: 10px;
                font-family: proxima_nova_semibold, Helvetica, sans-serif;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:12";