@font-face {
    font-family: 'icomoon';
    src:  url('./icons/icomoon.eot?fcujpo');
    src:  url('./icons/icomoon.eot?fcujpo#iefix') format('embedded-opentype'),
    url('./icons/icomoon.ttf?fcujpo') format('truetype'),
    url('./icons/icomoon.woff?fcujpo') format('woff'),
    url('./icons/icomoon.svg?fcujpo#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-angle-left:before {
    content: "\e900";
}
.icon-angle-right:before {
    content: "\e901";
}

;@import "sass-embedded-legacy-load-done:2";