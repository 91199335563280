$branded-data-height: 48px;
$product-image-wrapper-size: 80px;

.widget320x100 {
    @include flex(row, center, center);
    width: 100%;
    height: 100%;
    gap: 6px;

    &__left {
        @include flex(column, center, center);
        max-width: 120px;
        gap: 10px;
        padding: 0 5px;
    }

    &__link {
        @include flex(row, center, center);
        text-align: center;
        word-break: break-word;
        font-size: 13px;
    }

    &__logo {
        width: 100%;
        height: 40px;
        cursor: pointer;
        object-fit: contain;
    }

    &__offer-container {
        @include flex(row, center, center);
        width: 100%;
        height: 100%;
    }

    &__offer {
        font-weight: 400;
        font-size: 14px;
        text-align: center;
    }

    &-slide {
        @include flex(column, center, center);
        @include disable-focus-outline;
        position: relative;
        box-sizing: border-box;

        &.active {

            .widget320x100-slide__image {
                opacity: .25;
            }

            .widget320x100-slide__data {
                @include flex(column, center, center);
            }
        }

        &__image-wrapper {
            @include flex(column, center, center);
            width: $product-image-wrapper-size;
            height: $product-image-wrapper-size;
        }

        &__image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &__image--zoomed {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }

        &__data {
            display: none;
            bottom: 0;
            position: absolute;
            width: $product-image-wrapper-size;
            box-sizing: border-box;
        }

        &__title {
            @include multiline-text-ellipsis(12px, 1, 2);
            font-weight: bold;
            color: $gray-tundora;
        }

        &__price-box {
            @include flex(column, center, center);
            font-size: 13px;
            color: $gray-tundora;
            width: 100%;
            gap: 2px;
        }

        &__price--original {
            text-decoration: line-through;
            font-style: italic;
            color: $gray-dusty;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
        }

        &__action {
            width: 80px;
            height: 32px;
            color: $white;
            font-size: 14px;
            line-height: 1;
            font-weight: 600;
            text-transform: uppercase;
            border: none;
            border-radius: 2px;
            cursor: pointer;
            margin-top: 8px;
            @include disable-focus-outline;
        }
    }

    &__branding-wrapper {
        width: 100%;
        height: 14px;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: flex-end;

        .widget320x100__branding-main {
            width: 83px;
            height: 14px;
            cursor: pointer;
        }

    }

    &_kcp {
        .widget320x100__left {
            justify-content: space-around;
            align-items: center;
        }

        .widget320x100__logo {
            width: 100%;
            height: 40px;
        }

        .widget320x100__offer-container {
            max-width: 120px;
        }
    }

    .carousel-wrapper {
        height: 100%;
        padding: 0;
        box-sizing: border-box;
        max-width: 200px;
    }

    /* -------- slick-carousel styles overrides -------- */

    .view-carousel {
        height: 100%;
        margin: 0;
    }

    .slick-list {
        width: 100%;
    }

    .slick-track {
        height: 100%;
    }

    .slick-slide > div {
        display: flex;
        gap: 8px;
    }

    .slick-slide {
        @include flex(column, center, center);
    }

    .slick-slide > div > div {
        width: auto !important;
    }

    .slick-slide div {
        @include disable-focus-outline;
    }

    @include slick-controls(14px)
}

;@import "sass-embedded-legacy-load-done:20";