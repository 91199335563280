@import "./colors";
@import "./variables";
@import "./mixins";

.mp-modal.expandable {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: inherit;
    margin: 0;
    box-shadow: none;

    & * {
        box-sizing: border-box;
    }

    .mp-modal__back {
        top: 10px;
        left: 20px;
        width: 19px;
        height: 19px;

        @media (max-width: 480px) {
            left: 12px;
            width: 16px;
            height: 16px;
        }
    }

    .mp-modal__close {
        top: 10px;
        right: 20px;
        width: 19px;
        height: 19px;

        @media (max-width: 600px) {
            display: block;
        }

        @media (max-width: 480px) {
            display: block;
            right: 12px;
            width: 16px;
            height: 16px;
        }
    }

    .mp-modal-product {
        padding: 36px 74px 28px;
        max-height: calc(100vh - 2px);
        overflow: hidden;
        min-height: 0;

        @media (max-width: 728px) and (min-width: 601px) {
            padding: 30px 41px 4px;
        }

        @media (max-width: 600px) {
            padding: 50px 20px 30px;
            flex-direction: row;
        }

        @media (max-width: 480px) {
            flex-direction: column;
            padding: 30px 12px 0;
            max-height: 100%;
        }
    }

    .mp-modal-product__info {
        padding-left: 12px;

        @media (max-width: 728px) {
            width: 260px;
            min-width: 260px;
        }

        @media (max-width: 599px) {
            padding: 0;
            width: 100%;
        }
    }

    .mp-modal-product__carousel {
        @media (max-width: 599px) {
            padding-bottom: 20px;
        }


        @media (max-width: 720px) and (min-width: 600px) {
            max-width: 50%;
        }
    }

    .carousel {
        @media(max-width: 667px) and (min-width: 600px) {
            padding: 0;
        }
    }

    .carousel__slide {
        align-items: flex-start;
    }

    .carousel__slide-img {
        @media (max-width: 600px) {
            max-width: 100%;
            max-height: 250px;
        }
    }

    .info {
        height: 100%;
    }

    .info__title {
        @include multiline-text-ellipsis(18px, 1.25, 3);
        white-space: normal;
    }

    .info__brand {
        font-size: 16px;
        line-height: 38px;
        text-decoration: underline;
        @media (max-width: 728px) {
            line-height: 1;
        }
    }

    .info__tab {
        font-size: 16px;
        letter-spacing: .62px;

        &:after {
            height: 2px;
        }
    }

    .info-row {
        font-size: 16px;
        @media (max-width: 728px) {
            padding: 4px 0;
        }
    }

    .info-row__label {
        font-family: proxima_nova_semibold, Helvetica, sans-serif;
    }

    .info-row__label .icon {
        display: none;
    }

    .info-row.deal .info-row__value {
        font-size: 16px;
    }

    .info-buy__option-list {
        @media (max-width: 728px) {
            padding: 8px 0 7px;
        }
    }

    .info-buy__option {
        font-size: 16px;
    }

    .info-buy__option-values .scrollarea {
        @media (max-width: 480px) {
            padding-bottom: 24px;
        }
    }

    .info-details .scrollarea {
        @media (max-width: 970px) {
            max-height: 250px;
        }

        @media (max-width: 600px) and (min-width: 481px) {
            max-height: 96px;
        }

        @media (max-width: 480px) {
            max-height: 100px;
            min-height: 100px;
        }
    }

    .info-action-box {
        @media (max-width: 599px) {
            margin-top: 0;
            padding-bottom: 20px;
        }
    }

    .mp-modal-options__item {
        height: 30px;
        min-width: 30px;
        padding: 8px 9px 4px;
        font-size: 14px;
        text-align: center;
    }

    .mp-modal-qty {
        height: 30px;
    }

    .mp-modal-qty__button,
    .mp-modal-qty__value {
        width: 30px;
        height: 30px;
        border-color: #979797;

        @media (max-width: 677px) {
            color: #979797;
            font-size: 14px;
        }
    }

    .mp-modal-qty__button {

        @media (min-width: 728px) {
            padding: 0 0 2px;
        }
    }

    .mp-modal-options__item,
    .mp-modal-qty__button,
    .mp-modal-qty__value {
        @media (min-width: 728px) {
            border: 1px solid;
        }
    }

    .info-action-wrapper,
    .info-action-wrapper--favorites {
        height: 40px;
        padding: 0;
    }

    .info-action-wrapper--favorites {
        @media (max-width: 600px) {
            display: none;
        }
    }

    .info-action__icon {
        width: 24px;
        height: 20px;
    }

    .info-action {
        font-size: 18px;
        line-height: 16.6px;
    }


    // ---------- sign-step

    .mp-sign-in {
        @media (max-width: 970px) and (min-width: 768px) {
            padding: 46px 0;
        }
    }


    .mp-sign-in__logo-wrapper {
        width: 123px;
        height: 43px;
    }

    .mp-sign-in__box {
        @media (max-width: 728px) and (min-width: 600px) {
            flex-direction: row;
            align-items: stretch;
        }
    }

    .mp-sign-in__box .common-input-wraper input {
        min-height: 36px;
    }

    .mp-sign-in__part {
        max-width: 275px;

        &:not(.single):first-child {
            @media (max-width: 728px) and (min-width: 600px) {
                padding-right: 42px;
            }
        }

        &:not(.single):last-child {
            @media (max-width: 728px) and (min-width: 600px) {
                padding-left: 42px;
            }
        }

        @media (max-width: 728px) and (min-width: 600px) {

            &:not(.single):first-child {
                padding-right: 42px;
                padding-bottom: 0;
                border-bottom: 0;
                border-right: 1px solid #e2e2e2;
            }

            &:not(.single):last-child {
                padding-left: 42px;
            }
        }
    }

    .mp-sign-in__title {
        margin: 4px 0 10px;
        font-size: 20px;
    }

    .mp-sign-in__subtitle {
        font-size: 16px;
        line-height: 1.25;

        &:last-of-type {
            margin-bottom: 15px;
        }
    }

    .mp-sign-in__form-divider {
        padding: 0;
        margin-top: 6px;
        font-size: 14px;
        letter-spacing: 0;
    }

    .mp-sign-in .common-input-wraper {
        margin-top: 6px;
        padding-bottom: 0;
    }

    .mp-sign-in__fb {
        margin-top: 0;
    }

    .sign-in-form {
        margin-top: 0;
    }

    .sign-reset-form__title {
        font-size: 20px;
    }

    .sign-reset-form__message {
        font-size: 16px;
        line-height: 20px;
    }

    .sign-reset-form .forgot-link {
        display: none;
    }

    .reset-your-password {
        width: 100%;
    }

    .reset-your-password__title {
        margin: 4px 0 10px;
        font-family: proxima_nova_semibold, Helvetica, sans-serif;
        font-size: 20px;
        color: #4a4a4a;
    }

    .purchase-complete {
        padding-top: 55px;
        padding-bottom: 60px;
        flex-grow: 1;

        @media(max-width: 768px) and (min-width: 600px){
            flex-direction: row;
        }

        @media(max-width: 600px) {
            width: auto;
        }

        @media(max-width: 480px) {
            width: 100%;
        }
    }

    .purchase-complete .sign-in-form-wrapper {
        display: flex;
        align-items: center;
    }

    .purchase-complete .purchase-complete__title {
        font-size: 16px;
        white-space: nowrap;
    }

    .purchase-complete__img {
        width: 76px;
        height: 76px;
    }

    .purchase-complete__message {
        max-width: 227px;

        @media(max-width: 480px) {
            width: 100%;
        }
    }

    .purchase-complete__divider {
        min-height: 148px;
        margin-left: 33px;
        margin-right: 33px;

        @media(max-width: 768px) {
            width: 2px;
        }

        @media(max-width: 420px) {
            height: 2px;
            min-height: 0;
            max-width: 227px;
            width: 100%;
        }
    }

    .purchase-complete__message--widget {
        max-width: 470px;

        & > div {
            @media(max-width: 480px) {
                margin-left: 0;
            }
        }

        @media(max-width: 480px) {
            flex-direction: column;
            text-align: center;
        }
    }

    .purchase-complete__message--widget .purchase-complete__title {
        text-align: left;
        @media(max-width: 480px) {
            text-align: center;
        }
    }


    .purchase-complete-form {
        max-width: 227px;
    }

    .purchase-complete .purchase-complete-form__title {
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 22px;
    }

    .purchase-complete .purchase-complete-form .common-button {
        margin-top: 5px;
        margin-bottom: 4px;
    }

    .purchase-complete .purchase-complete-form__link {
        font-size: 14px;
    }


    // ---------- logged in as

    .logged-in {
        @media (max-width: 600px) {
            width: 50%;
        }

        @media (max-width: 480px) {
            width: 100%;
        }
    }

    .logged-in__logo-wrapper {
        width: 123px;
        height: 43px;
    }

    .logged-in__heading {
        margin-top: 36px;
        font-size: 20px;
    }

    .logged-in-form__field {
        height: 36px;
        line-height: 36px;
        font-size: 16px;
    }


    // ---------- purchase info

    .details {
        padding: 55px 50px;
        @media (max-width: 728px) {
            padding: 68px 20px 0 30px;
        }

        @media (max-width: 600px) {
            flex-direction: column;
            width: 253px;
            min-width: 253px;
            border-bottom: 0;
        }

        @media (max-width: 480px) {
            width: 100%;
            flex-direction: column-reverse;
            padding: 30px 20px 20px;
        }
    }

    .details-order {
        @media (max-width: 480px) {
            margin-top: 20px;
        }
    }

    .details__title {
        font-size: 20px;
        line-height: 18px;
        @media (max-width: 600px) {
            display: block;
            font-size: 18px;
        }

        @media (max-width: 480px) {
            margin-bottom: 10px;
        }
    }

    .details-product-wrapper {
        @media (max-width: 600px) {
            margin-top: 20px;
        }
    }

    .details-order__row {
        margin: 0;
        font-size: 14px;
        line-height: 16px;

        &:last-of-type {
            font-size: 16px;
        }

        &--subtotal {
            @media (max-width: 600px) {
                font-family: proxima_nova_regular, Helvetica, sans-serif;
            }
        }
    }

    .details-order__row--discount {
        margin-bottom: 7px;
    }

    .details-order__label .icon {
        width: 11px;
        height: 11px;
    }

    .details-product-wrapper {
        @media (max-width: 728px) {
            width: 100%;
        }
    }

    .details-product {
        @media (max-width: 728px) {
            flex-direction: column;
        }

        @media (max-width: 480px) {
            flex-direction: row;
        }
    }

    .details-product__image-wrapper {
        width: 68px;
        min-width: 68px;
        height: 68px;
        margin-right: 15px;

        @media (max-width: 728px) {
            width: 80px;
            min-width: 80px;
            height: 80px;

        }
    }

    .details-product__info {
        @media (max-width: 728px) {
            margin-top: 10px;
        }

        @media (max-width: 480px) {
            margin-top: 0;
        }
    }

    .details-product__name {
        @include multiline-text-ellipsis(14px, 1.2, 2)
    }

    .details-product__brand {
        margin: 3px 0;
        font-size: 14px;
        line-height: 18px;

        @media (max-width: 1024px) and (min-width: 769px) {
            max-width: 180px;
        }

        @media (max-width: 768px) {
            max-width: 100%;
            white-space: normal;
        }
    }

    .details-product__option {
        font-family: proxima_nova_regular, Helvetica, sans-serif;
        font-size: 13px;
        line-height: 18px;
    }


    // ---------- purchase steps

    .mp-modal-purchase {
        min-height: inherit;

        @media (max-width: 600px) {
            flex-direction: row;
            height: 100%;
        }

        @media (max-width: 480px) {
            flex-direction: column;
            max-height: 100%;
        }
    }

    .mp-modal-purchase__steps {
        padding: 70px 55px 24px 30px;
        @media (max-width: 728px) and (min-width: 601px) {
            padding: 68px 34px 34px 15px;
        }

        @media (max-width: 600px) and (min-width: 481px) {
            width: 58%;
            padding: 70px 20px 30px 15px;
        }

        @media (max-width: 480px) {
            width: 100%;
            padding: 20px;
        }
    }

    .mp-modal-purchase__step-list {
        margin-bottom: 4px;

        @media (max-width: 600px) {
            justify-content: flex-start;
        }
    }

    .mp-modal-purchase__step {
        font-size: 12px;
        letter-spacing: .46px;

        &:first-child {
            width: auto;

            @media (max-width: 600px) {
                width: 32px;
            }
        }

        &:not(:first-child) {
            padding-left: 14px;
        }

        &:not(:first-child):after {
            left: 2px;
            width: 6px;
            height: 6px;
            border-right: 1px solid $gray-silver;
            border-bottom: 1px solid $gray-silver;
        }
    }

    // --------- shipping and billing steps

    .mp-modal-purchase__shipping,
    .mp-modal-purchase__billing {
        margin-top: 16px;
    }

    .mp-modal-purchase__shipping .common-input-wraper,
    .mp-modal-purchase__billing .common-input-wraper {
        padding-bottom: 8px;
    }

    .mp-modal-purchase__shipping .error-message,
    .mp-modal-purchase__billing .error-message {
        line-height: 1.5;
    }

    .mp-modal-purchase__billing .form-control__input {
        max-height: 40px;
        padding: 2px;
        border: 1px solid #c7c7c7;
        font-weight: 600;
        border-radius: 2px;
    }

    .mp-modal-purchase__billing #stripe-iframe {
        width: 100%;
    }

    .mp-modal-purchase__billing .accordion {
        margin: 0 0 10px;
    }

    .mp-modal-purchase__shipping .state {
        width: calc(50% - 4px);
    }

    .mp-modal-purchase__shipping .zip {
        width: calc(50% - 4px);
        margin: 0;
    }

    .mp-modal-purchase__shipping .common-input-wraper input,
    .mp-modal-purchase__billing .common-input-wraper input{
        padding: 9px 10px;
        min-height: 40px;
    }

    .mp-modal-purchase__shipping .market-place-settings-content__actions,
    .mp-modal-purchase__billing .market-place-settings-content__actions {
        position: static;
        display: flex;
        justify-content: flex-end;
        margin-top: 22px;
        order: 5;
        padding: 0;
    }

    .mp-modal-purchase__shipping .common-button,
    .mp-modal-purchase__billing .common-button {
        width: 130px;
        padding: 9px 16px;
    }

    .mp-modal-purchase__billing .card-item__address {
        @media (max-width: 480px) {
            padding-left: 40px;
        }
    }

    .mp-modal-purchase__confirmation .card-item__address {
        @media (max-width: 480px) {
            padding-left: 66px;
        }
    }

    .mp-modal-purchase__billing .form-logos {
        order: 4;

        @media (max-width: 970px) {
            padding-bottom: 0;
        }
    }

    .mp-modal-purchase__shipping .three-inputs-row {
        flex-wrap: wrap;
    }

    .mp-modal-purchase__shipping .two-inputs-row {
        @media (max-width: 600px) {
            flex-direction: column;
        }
    }

    .mp-modal-purchase__shipping .two-inputs-row .common-input-wraper {
        @media (max-width: 600px) {
            margin: 0;
        }
    }

    .mp-modal-purchase__billing .two-inputs-row:not(:last-child) {
        @media (max-width: 600px) {
            flex-direction: column;

            .common-input-wraper {
                margin: 0;
            }
        }
    }

    .is-equal-with-shipping .common-input-wraper {
        padding-bottom: 0;
    }

    .is-equal-with-shipping .common-input-wraper > label {
        display: none;
    }

    .is-equal-with-shipping__checkbox {
        padding-bottom: 10px;
    }


    // --------- confirmation step

    .mp-modal-purchase__confirmation .wrapper-for-button {
        position: static;
    }

    .mp-modal-purchase__confirmation .common-button {
        padding: 9px;
        margin-top: 30px;
    }

    // --------- product carousel

    .product-carousel {
        @media (max-width: 600px) {
            display: flex;
            margin-top: 20px;
            height: auto;
            max-height: 300px;
        }
    }

    .product-carousel .slick-dots {
        @media (max-width: 600px) {
            top: -26px;
        }
    }

    .product-carousel .slick-track {
        @media (max-width: 600px) {
            display: flex;
        }
    }


    // --------- similar products

    .products-recommend-wrapper {
        @media (max-width: 599px) {
            padding-top: 20px;
        }
    }

    .products-recommend__overflow {
        min-height: 178px;
    }

    .products-recommend {
        @media (min-width: 601px) and (max-width: 728px) {
            padding: 20px 42px;
        }

        @media (max-width: 600px) {
            padding: 10px 0 18px 30px;
            min-height: 0;
        }
    }

    .products-recommend__title {
        font-size: 16px;
    }

    .products-recommend .product-card {
        @media (max-width: 600px) {
            margin-bottom: 0;
        }
    }

    // --------- common elements

    .accordion .accordion-address {
        padding: 6px 10px;
        font-size: 14px;
        line-height: 16px;
    }

    .accordion .accordion-address div.radio-input {
        @media (max-width: 480px) {
            margin-right: 10px;
        }
    }

    .accordion .edit-button {
        font-size: 11px;
    }

    .accordion .accordion-items-list > span {
        padding: 6px 14px 6px;
        font-size: 12px;
    }

    .user-email > div, .user-email > div {
        height: 38px;
        font-size: 14px;
        line-height: 38px;
    }

    .user-email > span, .user-email > span {
        font-size: 11px;
    }

    .common-input-wraper input {
        padding: 7px 10px;
        font-family: proxima_nova_regular, Helvetica, sans-serif;
        font-size: 16px;
    }

    .common-button {
        padding: 7px 16px;
        font-family: proxima_nova_semibold, Helvetica, sans-serif;
        font-size: 16px;
        line-height: 22px;
    }

    .auth__button_fb {
        width: 100%;
        padding: 9px 0 8px 15px;
        font-family: proxima_nova_semibold, Helvetica, sans-serif;
        border-radius: 2px;
        background-position: 15px;

        @media (max-width: 768px) and (min-width: 481px) {
            background-position: 24px;
        }

        @media (max-width: 480px) {
            background-position: 46px;
            padding-left: 20px;
        }
    }

    .forgot-link {
        font-size: 14px;
        line-height: 18px;
        transform: translate(-50%, 22px);
        @media (max-width: 600px) {
            left: 50%;
        }
    }

    .shipping-tab__name {
        margin-bottom: 0;
    }

    .card-element__wrapper {
        min-height: 0;
        padding-bottom: 0;
    }

    .card-item {
        flex-direction: row;
        padding: 9px 10px;
        font-size: 14px;

        @media (max-width: 480px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .card-item__info {
        width: 50%;
        white-space: nowrap;
        @media (max-width: 728px) {
            width: 100%;
        }
    }

    .card-item__address {
        flex-grow: 1;
        margin-top: 0;
        padding-right: 50px;
        padding-left: 20px;

        @media (max-width: 600px) {
            padding-right: 12px;
            padding-left: 6px;
        }
    }

    .card-item-logo img {
        @media (max-width: 600px) {
            margin-right: 4px;
        }
    }

    .card-item .expires {
        margin-left: 20px;
        @media (max-width: 600px) {
            margin-left: 4px;
        }
    }

    .security-logos__icon {
        max-width: 34px;
        max-height: 28px;
    }

    .mp-modal__special-link-text {
        font-size: 10px;
        text-align: center;
    }

    .form-logos__icon {
        width: 34px;
        height: 20px;
        margin-left: 4px;
    }

    .klickly-checkbox__label {
        font-size: 11px;
    }
}

.wrapper {

    &-320x480 {
        max-height: 480px;
    }

    &-970x500 {
        max-height: 500px;
    }

    &-600x600,
    &-728x600 {
        max-height: 600px;
        min-height: 600px;
    }

    .overlay {
        min-height: calc(100vh - 2px);
        max-height: calc(100vh - 2px);
        overflow-y: visible;
        overflow-x: hidden;
    }

    .notification-block {
        min-height: 30px;
        box-sizing: border-box;

        @media (max-width: 480px) {
            max-width: 280px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.wrapper-600x600 .marketplace-link-wrapper {
    padding: 6px 5px 1px;
}

.wrapper-320x480 .marketplace-link-wrapper {
    padding-right: 12px;
}

;@import "sass-embedded-legacy-load-done:13";