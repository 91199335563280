
$black: #0A0A0A;
$pink: #DB1C78;

.widgetH90 {
    @include flex(row, stretch, stretch);
    position: relative;
    width: 100%;
    height: 100%;

    &-products-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-around;
    }

    &-product {
        @include flex(row, center, stretch);
        width: 290px;
        cursor: pointer;
    }

    &__left {
        @include flex(column, center, center);
        width: 155px;
        height: 60px;
        background-color: $white;
        margin: auto 7px;
        padding-right: 7px;

        &.no-logo {
            box-sizing: border-box;
            background-color: $white;
            text-align: center;
        }
    }

    &__link {
        @include flex(row, center, center);
        height: 50%;
        width: 100%;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        line-height: 1;
        word-break: break-all;
    }

    &__offer {
        display: inline-block;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        line-height: 16px;

        max-height: 36px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &__logo {
        width: 100%;
        object-fit: contain;
        cursor: pointer;
    }

    &__action {
        padding: 9px 18px;
        font-size: 11px;
        line-height: 1;
        color: $white;
        text-transform: uppercase;
        background-color: $red-violet;
        border: none;
        border-radius: 2px;
        cursor: pointer;

        @include disable-focus-outline;
    }

    &__offer-wrapper {
        @include flex(row, center, center);
        text-align: center;
        height: 50%;
        width: 100%;
        font-size: 14px;
        line-height: 1.14;
        color: $red-violet;
        text-transform: uppercase;
    }

    &-product__image-wrapper {
        @include flex(row, center, center);
        width: 90px;
        height: 90px;
        padding-left: 8px;
    }

    &-product__image {
        max-height: 90px;
        max-width: 90px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        object-fit: cover;
    }

    &-product__image--zoomed {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }

    &-product__content-wrapper {
        @include flex(row, flex-start, center);
        align-self: stretch;
        flex-grow: 1;
        width: 59.5%;
        padding: 0 10px;
        box-sizing: border-box;

        .widgetH90__action {
            display: none;
        }

        @include disable-focus-outline;
    }

    &-product-wrapper {
        width: 100%;
        font-family: Arial, Helvetica, sans-serif;
        line-height: 16px;
        letter-spacing: 0;
        text-align: left;
    }

    &-product__content {
        width: 100%;
    }

    &-product__title {
        font-weight: bold;
        color: $black;
        @include multiline-text-ellipsis(12px, 1.2, 2);
        word-wrap: break-word;
    }

    &-product__brand-name {
        color: $black;
        @include multiline-text-ellipsis(10px, 1.2, 2);
        padding: 5px 0 0;
    }

    &-product__price-box {
        width: 100%;
        margin-top: 5px;
        @include flex(row, space-between, center);
        font-size: 15px;
    }

    &-product__price,
    &-product__price--original {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-product__price {
        font-weight: 600;
        color: $black;
        font-size: 12px;
        margin-right: 5px;
    }

    &-product__price--original {
        margin-left: 2px;
        font-size: 11px;
        text-decoration: line-through;
        text-align: left;
        color: $black;
    }

    &-product__discount {
        font-size: 12px;
        font-weight: 600;
        color: $pink;
    }

    &__branding-wrapper {
        width: 83px;
        height: 14px;
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: pointer;

        .widgetH90__branding-main {
            width: 83px;
            height: 14px;
        }
    }

    &_kcp {
        .widgetH90__logo {
            width: 74px;
            height: 29px;
        }

        .widgetH90__left {
            justify-content: space-around;
            height: 100%;
        }
    }
}

;@import "sass-embedded-legacy-load-done:14";