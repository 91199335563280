$branded-data-height: 48px;
$product-image-wrapper-size: 100px;

.widget336x280 {
    @include flex(column, stretch, stretch);
    width: 100%;
    height: 100%;

    &__left {
        @include flex(row, stretch, stretch);
        max-width: 100%;
        height: $branded-data-height;
    }

    &__link {
        width: 50%;
        @include flex(row, center, center);
        text-align: center;
    }

    &__logo {
        width: 50%;
        cursor: pointer;
        object-fit: contain;
    }

    &__offer-container {
        @include flex(row, center, center);
        width: 50%;
        height: 100%;
    }

    &__offer {
        font-weight: 600;
        font-size: 16px;
        text-align: center;
    }

    &-slide {
        @include flex(column, center, center);
        @include disable-focus-outline;
        position: relative;
        padding: 4px 2px;
        box-sizing: border-box;

        &.active {

            .widget336x280-slide__image {
                opacity: .25;
            }

            .widget336x280-slide__data {
                @include flex(column, center, center);
            }
        }

        &__image-wrapper {
            @include flex(column, center, center);
            width: $product-image-wrapper-size;
            height: $product-image-wrapper-size;
        }

        &__image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &__image--zoomed {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }

        &__data {
            display: none;
            position: absolute;
            width: $product-image-wrapper-size;
            padding: 0 2px;
            box-sizing: border-box;
        }

        &__title {
            @include multiline-text-ellipsis(12px, 1, 2);
            font-weight: bold;
            color: $gray-tundora;
        }

        &__price-box {
            @include flex(row, center, center);
            margin: 4px 0;
            font-size: 12px;
            color: $gray-tundora;
            width: 100%;
        }

        &__price--original {
            margin-left: 4px;
            text-decoration: line-through;
            font-style: italic;
            color: $gray-dusty;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
        }

        &__action {
            width: 84px;
            height: 32px;
            color: $white;
            font-size: 14px;
            line-height: 1;
            font-weight: 600;
            text-transform: uppercase;
            border: none;
            border-radius: 2px;
            cursor: pointer;
            @include disable-focus-outline;
        }
    }

    &__branding-wrapper {
        width: 100%;
        height: 14px;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: flex-end;

        .widget336x280__branding-main {
            width: 83px;
            height: 14px;
            cursor: pointer;
        }

    }

    &_kcp {
        .widget336x280__left {
            justify-content: space-around;
            align-items: center;
        }

        .widget336x280__logo {
            width: 74px;
            height: 29px;
        }

        .widget336x280__offer-container {
            width: 160px;
        }
    }

    .carousel-wrapper {
        height: calc(100% - (#{$branded-data-height}) - 14px);
        padding: 0;
        box-sizing: border-box;
    }

    /* -------- slick-carousel styles overrides -------- */

    .view-carousel {
        height: 100%;
        padding: 0;
        margin: 0;
    }

    .slick-list {
        width: 100%;
    }

    .slick-track {
        display: flex;
        height: 100%;
    }

    .slick-slider {
        display: flex;
        flex-direction: column;
        gap: 14px;
    }

    .slick-dots {
        pointer-events: none;
    }

    .slick-slide > div {
        display: flex;
    }

    .slick-slide.slick-slide {
        @include flex(column, flex-start, center);
    }

    .slick-slide > div > div {
        width: auto !important;
    }

    .slick-slide div {
        @include disable-focus-outline;
    }

    @include slick-controls(14px)
}

;@import "sass-embedded-legacy-load-done:21";