.widget600-carousel {
    .carousel-wrapper {
        .view-carousel {
            .line {
                margin-top: 10px;
            }
        }

        .slick-list {
            margin-top: 10px;
        }

        .icon {
            font-size: 22px;
            color: $gray-dusty-2;
            text-shadow: none;
            top: 50%;
            transform: translateY(-50%);
        }

        .slick-prev,
        .slick-next {
            top: -18px;
        }

        .slick-prev {
            .icon {
                left: 10px;
            }
        }

        .slick-next {
            .icon {
                right: 10px;
            }
        }
    }

    .slick-dots {
        display: flex !important;
    }
}





;@import "sass-embedded-legacy-load-done:11";