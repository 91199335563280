$left-block-width: 46.875%;
$right-block-width: 53.125%;
$product-image-wrapper-width: 50px;

$black: #0A0A0A;
$pink: #DB1C78;

.widgetH50 {
    @include flex(row, stretch, stretch);
    position: relative;
    width: 100%;
    height: 100%;
    font-family: 'montserrat-regular', Helvetica, sans-serif;

    &-products-wrapper {
        width: fit-content;
        display: flex;
        justify-content: space-around;
    }

    &-product {
        @include flex(row, center, stretch);
        width: 195px;
        height: 100%;
        margin-left: 8px;
        @include disable-focus-outline;
        cursor: pointer;
    }

    &__left {
        width: 100px;
        height: 90%;
        margin: auto 0;
        padding-right: 7px;

        &.no-logo {
            @include flex(column, center, center);
            box-sizing: border-box;
            background-color: $white;
            text-align: center;
        }
    }

    &__logo {
        width: 100%;
        height: 100%;
        object-fit: contain;
        cursor: pointer;
    }

    &__link {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        line-height: 1;
        word-break: break-all;
    }

    &-product__image-wrapper {
        @include flex(row, center, center);
        width: 50px;
        height: 50px;
    }

    &-product__image {
        display: block;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        object-fit: cover;
    }

    &-product__image--zoomed {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }

    &-product__content-wrapper {
        @include flex(row, flex-start, center);
        width: 145px;
        padding: 0 3px 0 5px;
        text-align: center;

        .widgetH50__action {
            display: none;
            padding: 9px 18px;
        }
    }

    &-product__content {
        width: 100%;
        padding-left: 5px;
    }

    &-product__title {
        font-weight: bold;
        color: $black;
        @include multiline-text-ellipsis(10px, 1.2, 1);
        word-wrap: break-word;
        text-align: left;
    }

    &-product__brand-name {
        font-size: 8px;
        line-height: 13px;
        color: $black;
        font-family: 'montserrat-regular', Helvetica, sans-serif;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
    }

    &-product__offer-message {
        font-size: 12px;
        line-height: 1.33;
    }

    &-product__price-box {
        @include flex(row, space-between, flex-end);
        width: 100%;
        box-sizing: border-box;
    }

    &-product__price {
        font-size: 10px;
        line-height: 1.14;
        color: $black;
        font-weight: 600;
    }

    &-product__discount {
        font-size: 10px;
        line-height: 1.14;
        color: $pink;
        font-weight: 600;
    }

    &-product__price--single {
        text-align: center;
    }

    &-product__price--original {
        margin-left: 6px;
        font-size: 8px;
        color: $black;
        text-decoration: line-through;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-product__action {
        max-width: 73px;
        max-height: 22px;
        padding: 5px 15px;
        margin-top: 2px;
        font-size: 9px;
        color: $white;
        text-transform: uppercase;
        border: none;
        border-radius: 1.5px;
        cursor: pointer;
        @include disable-focus-outline;
    }

    &_kcp {
        .widgetH50__left {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .widgetH50__logo {
            width: 74px;
            height: 29px;
        }
    }

    &__branding-wrapper {
        width: 14px;
        height: 14px;
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: pointer;

        .widgetH50__branding-main {
            width: 14px;
            height: 14px;
        }

        .widgetH50__branding-hover {
            display: none;
        }

        &:hover {
            background: none;
            width: 52px;
            height: 9px;

            .widgetH50__branding-main {
                display: none;
            }

            .widgetH50__branding-hover {
                display: block;
                width: 52px;
                height: 9px;
                cursor: pointer;
            }
        }
    }
}

.widgetH50__action {
    padding: 9px 18px;
    font-size: 11px;
    line-height: 1;
    color: #FFFFFF;
    text-transform: uppercase;
    background-color: #DA1781;
    border: none;
    border-radius: 2px;
    cursor: pointer;
}

;@import "sass-embedded-legacy-load-done:16";