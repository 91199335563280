$left-block-width: 190px;
$slider-width: calc(100% - #{$left-block-width});
$product-image-wrapper-size: 150px;

.widget970 {
    display: flex;
    width: 100%;
    height: 100%;

    &__left-wrapper {
        @include flex(column, center, center);
    }

    &__left.no-logo {
        @include flex(column, space-between, stretch);
        box-sizing: border-box;
        background-color: transparent;
        text-align: center;
    }

    &__logo {
        width: 100%;
        height: auto;
        background-color: $white;
        cursor: pointer;
    }

    &__offer {
        margin-top: 6px;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
    }

    &__action {
        margin-top: 4px;
        width: 94px;
        height: 35px;
        font-size: 14px;
        font-weight: 600;
        color: $white;
        border: none;
        border-radius: 2px;
        cursor: pointer;
        text-transform: uppercase;
        @include disable-focus-outline;
    }

    &-slide {
        height: 100%;
        box-sizing: border-box;
        @include flex(column, center, center);
        @include disable-focus-outline;

        &.active {

            .widget970-slide__image {
                opacity: .25;
            }

            .widget970-slide__title {
                visibility: visible;
            }
        }
    }

    &-slide__top {
        position: relative;
        width: $product-image-wrapper-size;
        height: $product-image-wrapper-size;
        @include flex(row, center, center);
    }

    &-slide__bottom {
        margin-top: 10px;
    }

    &-slide__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &-slide__image--zoomed {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }

    &-slide__title {
        max-width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        color: $gray-tundora;
        visibility: hidden;
        @include multiline-text-ellipsis(22px, 1.18, 3);
    }

    .widget970-slide__price-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $product-image-wrapper-size;
        @include flex(row, center, center);
    }

    .widget970-slide__price,
    .widget970-slide__price--original {
        flex-basis: 50%;
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .widget970-slide__price {
        font-size: 16px;
        text-align: right;
    }

    .widget970-slide__price--single {
        text-align: center;
    }

    .widget970-slide__price--original {
        margin-left: 10px;
        font-size: 15px;
        font-style: italic;
        color: $gray-dusty;
        text-decoration: line-through;
        text-align: left;
    }

    &_kcp {
        .widget970__logo {
            width: 110px;
            height: 43px;
        }
    }

    &__branding-wrapper {
        width: 83px;
        height: 14px;
        position: absolute;
        right: 0;
        bottom: 0;

        .widget970__branding-main {
            width: 83px;
            height: 14px;
            cursor: pointer;
        }

    }

    @include slick-controls(33px, large);

    /* -------- slick-carousel styles overrides -------- */

    .carousel-wrapper {
        height: calc(100% - 14px);
        padding: 0;
    }

    .slick-list {
        max-height: 100%;
        width: 100%;
    }

    .slick-dots {
        pointer-events: none;
    }

    .slick-slide {
        -webkit-transform: translate3d(0,0,0);
    }

    .slick-track {
        height: 100%;
    }

    .slick-slide > div {
        height: 100%;
        @include flex(row, center, center);
    }

    .slick-slide div {
        @include disable-focus-outline;
    }

    .view-carousel.view-carousel {
        margin: 0;
        width: 100%;
        height: 100%;
        padding: 0 25px;
    }

    .slick-slide > div {
        display: flex;
        justify-content: space-around;
        pointer-events: none;
    }

    .slick-slide > div > * {
        pointer-events: auto;
    }
}

;@import "sass-embedded-legacy-load-done:17";