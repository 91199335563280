$branded-logo-height: 60px;
$branded-offer-height: 50px;
$branded-data-height: $branded-logo-height + $branded-offer-height;
$product-image-wrapper-size: 132px;

$black: #0A0A0A;
$pink: #DB1C78;

.widgetW160 {
    @include flex(column, stretch, stretch);
    height: 100%;

    &__left {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 50px;
        padding-top: 6px;

        &.no-logo {
            @include flex(column, space-between, stretch);
            box-sizing: border-box;
            background-color: $white;
            border-right: 1px solid $gray-gallery;
            text-align: center;
        }

        &.no-logo .widgetW160__link {
            margin-top: 40px;
        }
    }

    &__logo {
        width: 120px;
        height: 50px;
        object-fit: contain;
        cursor: pointer;
        padding-bottom: 5px;
        border-bottom: 1px solid #CAC3C3;
    }

    &__offer-container {
        @include flex(row, center, center);
        height: $branded-offer-height;
        font-size: 18px;
    }

    &-slide {
        @include flex(column, center, center);
        @include disable-focus-outline;
        position: relative;
        padding: 5px;
        box-sizing: border-box;

        &.active {

            .widgetW160-slide__title-block {
                opacity: 0;
            }

            .widgetW160-slide__data {
                @include flex(column, flex-start, center);
            }
        }

        &__content {
            width: 138px;
            height: 175px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        &__image-wrapper {
            @include flex(column, center, center);
            width: 115px;
            height: 115px;
        }

        &__image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &__image--zoomed {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }

        &__data {
            width: $product-image-wrapper-size;
            display: none;
            position: absolute;
            top: 0;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%);
        }

        &__title {
            @include multiline-text-ellipsis(10px, 1, 2);
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: bold;
            color: $black;
            margin-bottom: 3px;
        }

        &__title-block {
            width: 115px;
        }

        &__brand-name {
            @include multiline-text-ellipsis(9px, 1.2, 1);
            max-width: 115px;
            color: $black;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__price-box {
            width: 100%;
            @include flex(column, center, center);
            padding-top: 5px;
        }

        &__price {
            font-size: 12px;
            line-height: 1.25;
            color: $black;
            font-weight: 600;
        }

        &__price--original {
            font-size: 10px;
            line-height: 1.25;
            font-style: italic;
            color: $black;
            text-decoration: line-through;
        }

        &__price, &__price--original {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
        }

        &__discount {
            font-weight: 600;
            font-size: 12px;
            line-height: 1.5;
            color: $pink;
        }

        &__action {
            width: 116px;
            height: 43px;
            font-size: 16px;
            font-weight: 600;
            color: $white;
            text-transform: uppercase;
            border: none;
            border-radius: 2px;
            cursor: pointer;

            @include disable-focus-outline;
        }
    }

    
    &__branding-wrapper {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: pointer;

        .widgetW160__branding-main {
            width: 20px;
            height: 20px;
        }

        .widgetW160__branding-hover {
            display: none;
        }

        &:hover {
            background: none;
            width: 83px;
            height: 14px;

            .widgetW160__branding-main {
                display: none;
            }

            .widgetW160__branding-hover {
                display: block;
                width: 83px;
                height: 14px;
                cursor: pointer;
            }

        }
    }

    @include slick-controls(13px);

    /* -------- slick-carousel styles overrides -------- */

    .carousel-wrapper {
        padding-top: 0;
        height: 100%
    }

    .view-carousel {
        padding: 7px 10px 5px;
        box-sizing: border-box;
    }

    .view-carousel {
        height: 100%;
        margin: 0;
    }

    .slick-list {
        width: 100%;
    }

    .slick-track {
        height: 100%;
    }

    .slick-slide {
        @include flex(column, space-between, stretch);
    }

    .slick-slide div {
        @include disable-focus-outline;
    }

    .slick-slide > div {
        display: flex;
    }
}

;@import "sass-embedded-legacy-load-done:24";